export const FooterNavList = [
  {
    title: "Novadan.com.tr de Satış Yapmak",
    sub_categories: [
      {
        title: "Novadan da Nasıl İlan Verebilirim",
        url: "https://sss.novadan.com.tr/novadan-da-nasil-ilan-verebilirim/",
      },
      {
        title: "Novada Nasıl Satış Yapabilirim",
        url: "https://sss.novadan.com.tr/novada-nasil-satis-yapabilirim/",
      },
      {
        title: "Novadanda Nasıl Kargo Kampanyası Yapabilirim ?",
        url: "https://sss.novadan.com.tr/novadanda-nasil-kargo-kampanyasi-yapabilirim/",
      },
      {
        title: "Novadan Yeni Nesil Pazaryeri Satıcı Üye Sözleşmesi",
        url: "https://sss.novadan.com.tr/novadan-yeni-nesil-pazaryeri-satici-uye-sozlesmesi/",
      },
      {
        title: "Novadan da Satışa Yasaklı Ürünler",
        url: "https://sss.novadan.com.tr/novadan-da-satisa-yasakli-urunler/",
      },
      {
        title: "Novadan da ÜTS Bildirimi",
        url: "https://sss.novadan.com.tr/novadan-uts-bildirimi/",
      },
    ],
  },
  {
    title: "NOVADAN NEDİR?",
    sub_categories: [
      {
        title: "Kişisel Verilerin Korunması",
        url: "https://sss.novadan.com.tr/novadan-kisisel-verilerin-korunmasi-ve-islenmesi-politikasi/",
      },
      {
        title: "Veri İşlenme Politikası",
        url: "https://sss.novadan.com.tr/novadan-kisisel-verilerin-korunmasi-ve-islenmesi-politikasi/",
      },
      {
        title: "Çerez Politikası",
        url: "https://sss.novadan.com.tr/novadan-cerez-politikasi/",
      },
      {
        title: "KVKK Gizlilik Sözleşmesi",
        url: "https://sss.novadan.com.tr/novadan-kvkk-gizlilik-sozlesmesi/",
      },
      {
        title: "Üyelik Sözleşmesi",
        url: "https://sss.novadan.com.tr/novadan-uyelik-sozlesmesi/",
      },
      {
        title: "Novadan ile İletişim",
        url: "https://sss.novadan.com.tr/novadan-ile-iletisim/",
      },
      {
        title: "Novadan Nedir ?",
        url: "https://sss.novadan.com.tr/novadan-nedir/",
      },
    ],
  },
  {
    title: "ALIŞVERİŞ HAKKINDA",
    sub_categories: [
      {
        title: "Nasıl Sipariş Veririrm",
        url: "https://sss.novadan.com.tr/novadanda-nasil-siparis-veririrm/",
      },
      {
        title: "Güvenli Ödeme Sistemi",
        url: "https://sss.novadan.com.tr/novadan-guvenli-odeme-sistemi/",
      },
      {
        title: "Nasıl Üye Olabilirim",
        url: "https://sss.novadan.com.tr/novadana-nasil-uye-olabilirim/",
      },
      {
        title: "Ertesi Gün Teslimat",
        url: "https://sss.novadan.com.tr/novadan-da-ertesi-gun-teslimat/",
      },
      {
        title: "Kargo Avantajları",
        url: "https://sss.novadan.com.tr/novadan-da-kargo-avantajlari/",
      },
      {
        title: "İptal ve İade Koşulları",
        url: "https://sss.novadan.com.tr/novadan-da-iptal-ve-iade-kosullari/",
      },
      {
        title: "Destek Hattı",
        url: "https://sss.novadan.com.tr/siparisimle-ilgili-nasil-destek-alabilirim/",
      },
    ],
  },
];
